<template>
  <div class="demo-filterForm">
    <el-form
      :model="filterForm"
      ref="refFilter"
    >

      <el-form-item class="me-6">
        <label class="d-block">{{ $t("Time Period") }}</label>
        <el-select
          v-model="filterForm.time_period"
          :placeholder="$t('Time Period')"
        >
          <el-option
            v-for="(item,index) in timePeriods"
            :key="index"
            :label="item.title"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="me-6">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogProductAreaVisible = true" class="select-popup"></div>

          <label class="d-block">{{ $t("Product Area") }}</label>
          <el-select multiple disabled v-model="filterForm.product_areas" collapse-tags>
            <el-option
              v-for="(item,index) in productAreas"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item class="me-6">
        <div class="cursor-pointer position-relative z-index-1">
          <div @click="dialogCountriesVisible = true" class="select-popup"></div>

          <label class="d-block mt-5" for="">{{ $t("Country of the Bank") }}</label>
          <el-select multiple disabled v-model="filterForm.countries" collapse-tags>
            <el-option
                v-for="(item,index) in countries"
                :key="index"
                :label="item"
                :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </el-form>

    <div class="filters-counts" v-show="filterCount">
      <span>
        <div class="filter-badges not-styles">{{ filterCount }}</div>
        {{ $t('Filters Selected') }}
      </span>
      <button @click="clearAll">{{ $t('Clear All') }}</button>
    </div>

    <div class="me-6">
      <button
        class="main-btn-outline filter-btn mt-6"
        :class="{'mt-20': !filterCount}"
        @click="submitForm"
      >
        {{ $t("Apply Filters") }}
      </button>
    </div>
  </div>

  <div v-if="dialogBanksVisible">
    <SelectModal
      :popupVisible="dialogBanksVisible"
      :dataForSelect="banks"
      :dataForUpdate="filterForm.bank_ids"
      :multiple="true"
      :title="$t('Select Banks')"
      :subtitle="$t('Select relevant banks')"
      :placeholder="$t('Search Banks')"
      @handle-close="dialogBanksVisible = false"
      @handle-confirm="handleConfirm"
    />
  </div>

  <SelectModal
    v-if="dialogProductAreaVisible"
    :title="$t('Select Product Areas')"
    :subtitle="$t('Select Product Areas you want filter on')"
    :placeholder="$t('Search Product Areas')"
    :popupVisible="dialogProductAreaVisible"
    :dataForSelect="productAreas"
    :dataForUpdate="filterForm.product_areas"
    :multiple="true"
    :selfValue="true"
    @handle-close="dialogProductAreaVisible = false"
    @handle-confirm="handleConfirmProductAreas"
  />

  <SelectModal
    v-if="dialogCountriesVisible"
    :title="$t('Select Country')"
    :subtitle="$t('Select Country you want to filter on')"
    :placeholder="$t('Search Country')"
    :popupVisible="dialogCountriesVisible"
    :dataForSelect="countries"
    :dataForUpdate="filterForm.countries"
    :multiple="true"
    :selfValue="true"
    @handle-close="dialogCountriesVisible = false"
    @handle-confirm="handleConfirmCountries"
  />
</template>

<script>
  import SelectModal from "@/buying-teams/shared-components/modals/SelectModal";
  import BankFilterBadges from "@/buying-teams/shared-components/filter/BankFilterBadges";

  export default {
    name: "BankFeedbackFilter",
    components: {
      BankFilterBadges,
      SelectModal
    },

    props: {
      banks: Array,
      countries: Array,
      productAreas: Array,
      timePeriods: Array,
      initialFilters: Object,
      filterCount: Number
    },

    emits: [
      "handle-apply-filter",
      "getSize"
    ],

    computed: {
      filterAllData() {
        return {
          all_product_areas: this.productAreas,
          all_time_period: this.timePeriods,
          all_countries: this.countries,
        }
      }
    },

    created() {
      this.fillFilter(this.initialFilters);
    },

    data() {
      return {
        dialogBanksVisible: false,
        dialogCreatorsVisible: false,
        dialogProductAreaVisible: false,
        dialogCountriesVisible: false,

        filterForm: {
          product_areas: [],
          time_period: "*",
          countries: [],
        },
      };
    },

    methods: {
      handleConfirm(event) {
        this.filterForm.bank_ids = event;
        this.dialogFormVisible = false;
      },
      submitForm() {
        this.$emit("handle-apply-filter", this.filterForm);
      },
      fillFilter(filterData) {
        this.filterForm.countries = filterData.countries;
        this.filterForm.product_areas = filterData.product_areas;
        this.filterForm.time_period = filterData.time_period;
      },
      handleConfirmProductAreas(event) {
        this.filterForm.product_areas = event;
        this.dialogProductAreaVisible = false;
      },
      handleConfirmCountries(event) {
        this.filterForm.countries = event;
        this.dialogCountriesVisible = false;
      },
      clearAll() {
        this.filterForm.countries = this.countries;
        this.filterForm.product_areas = this.productAreas;
        this.filterForm.time_period = '*';
        this.submitForm();
      },
    }
  };
</script>

<style lang="scss">
</style>
