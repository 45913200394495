<template>
  <div class="feedbacks-page card card-padding h-100" :class="{ 'is-show-filter': showFilter }">
    <PageHeader
      @showFilter="showFilter = true"
      :disabled="filterLoading"
      :showFilter="showFilter"
      create-button-link="/bank/feedback/feedback-session/create"
      :create-button-text="$t('Create Feedback')"
      :search-input-text="$t('Search feedback...')"
      :allow-create="currentUser.can(BankUserPermissionTypeEnum.CREATE_FEEDBACK_SESSION)"
    >
      <template #filter-badges>
        <BankFilterBadges
          :filterData="getFilterForm"
          :allData="filterAllData"
          :not-styles="true"
          @getSize="filterCount = $event"
        />
      </template>
    </PageHeader>

    <div class="feedbacks-wrapper">
      <div
          v-if="showFilter"
          class="filter feedbacks-wrapper__filter">
        <p
          @click="showFilter = false"
          class="closeFilter">
          <img width="20" src="/media/buying/icons/close.svg" alt="">
        </p>
        <BankFeedbackFilter
          :initialFilters="filterData.filter"
          :banks="banks"
          :countries="bankCountries"
          :productAreas="bankProductAreas"
          :timePeriods="timePeriods"
          :filterCount="filterCount"
          @handle-apply-filter="applyFilter"
        />
      </div>

      <div class="feedbacks-wrapper__list">
        <template
          v-if="!loading && surveys.length"
          v-for="item in surveys"
          :key="item.id"
        >
          <BankFeedbackItem
            :feedback="item"
            :showFilter="showFilter"
          />
        </template>

        <div
          v-else-if="!loading && !surveys.length"
          class="h-100 d-flex align-items-center justify-content-center"
        >
          <CustomLoader height="400px" v-if="filterLoading" />
          <RequestIdeaEmptyState
            v-else
            :title="$t('No Session Found')"
            :text="filterCount
              ? $t('There are no feedback sessions to be found. It might have happened due to the filters applied. Please check filters or')
              : $t('There are no feedback sessions created yet. Please check back later or')
            "
            :icon-path="filterCount
              ? '/media/buying/icons/no-session-found-filter.png'
              : '/media/buying/icons/no-session-found.svg'
            "
            :show-button="currentUser.can(BankUserPermissionTypeEnum.CREATE_FEEDBACK_SESSION)"
            :button-text="$t('Create Feedback')"
            @handleClickButton="$router.push('/bank/feedback/feedback-session/create')"
          />
        </div>

        <CustomLoader height="400px" v-else />
      </div>
    </div>
    <div>
    </div>
  </div>
</template>
<script>
import BankFeedbackItem from "@/buying-teams/pages/bank/feedbacks/sections/BankFeedbackItem";
import store from "@/store";
import Loader from "@/buying-teams/shared-components/utils/Loader";
import CustomLoader from "../../../shared-components/utils/CustomLoader";
import { BusinessFeedbackSessionFilterModel } from "@/store/models/filter/BusinessFeedbackSessionFilterModel";
import PageHeader from "@/buying-teams/shared-components/feedbacks/PageHeader";
import {setCurrentPageBreadcrumbs, setCurrentPageCounter} from "@/core/helpers/breadcrumb";
import BankFeedbackFilter from "@/buying-teams/pages/bank/feedbacks/components/BankFeedbackFilter";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";
import BankFilterBadges from "@/buying-teams/shared-components/filter/BankFilterBadges";
import RequestIdeaEmptyState from "@/buying-teams/shared-components/utils/RequestIdeaEmptyState";

export default {
  name: "BankFeedbacks",

  components: {
    RequestIdeaEmptyState,
    BankFilterBadges,
    BankFeedbackFilter,
    PageHeader,
    BankFeedbackItem,
    Loader,
    CustomLoader
  },

  data() {
    return {
      filterLoading: true,
      BankUserPermissionTypeEnum,
      showFilter: false,
      filterCount: 0,
      filterData: {
        type: "survey",
        search: "",
        filter: {
          product_areas: [],
          time_period: "*",
          countries: [],
        }
      }
    }
  },

  computed: {
    surveys() {
      return store.getters.getBankFeedbacksData
    },
    loading() {
      return store.getters.getBankFeedbacksLoading
    },
    banks() {
      return store.getters.businessBanks;
    },
    timePeriods() {
      return store.getters.timePeriods;
    },
    bankCountries() {
      return store.getters.getBankFiltersState.survey.countries;
    },
    bankProductAreas() {
      return store.getters.getBankFiltersState.survey.product_areas;
    },
    bankFeedbackFilterData() {
      return store.getters.getBankFeedbackFilterData;
    },
    currentUser() {
      return store.getters.currentUser;
    },
    getFilterForm() {
      return {
        product_areas: this.filterData.filter.product_areas,
        time_period: this.filterData.filter.time_period,
        countries: this.filterData.filter.countries,
      }
    },
    filterAllData() {
      return {
        all_product_areas: this.bankProductAreas,
        all_time_period: this.timePeriods,
        all_countries: this.bankCountries,
      }
    }
  },

  async mounted() {
    setCurrentPageBreadcrumbs({
      title: this.$t('Feedback Sessions'),
      subTitle: this.$t('Score or review your Banks\' performance')
    })
      store.commit('SAVE_BANK_FEEDBACK_LOADING', true);
      await store.dispatch('initFiltersData', { type: BankFiltersTypeEnum.SURVEY })

    if (!this.bankFeedbackFilterData) {
      this.filterData.filter.product_areas = this.bankProductAreas;
      this.filterData.filter.countries = this.bankCountries;
    } else {
      this.setFilters(this.bankFeedbackFilterData)
    }

    await this.getSurveys(this.filterData);

    this.filterLoading = false;
  },

  methods: {
    async getSurveys(filter) {
        await store.dispatch('getBankFeedbacksSessions', filter)
      setCurrentPageCounter({counter: this.surveys.length})
    },
    applyFilter(filters) {
        setCurrentPageCounter({counter: null});
        store.commit('SAVE_BANK_FEEDBACK_LOADING', true);
        this.setFilters(new BusinessFeedbackSessionFilterModel(filters));
      this.getSurveys(this.filterData);
    },
    setFilters(filters) {
      this.filterData.filter = filters;
    },
  }
}
</script>
<style lang="scss" scoped>
.filter-badges {
  width: 21px;
  height: 21px;
  background: #E22D21;
  border: 1px solid #FFFFFF;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  left: -18px;
}
</style>
