<template>
  <div
    class="bank-feedback-item mt-5"
    :class="{
      ['bank-feedback-item__' + feedback.status.statusClass]: true,
      'show-filter': showFilter
    }"
  >
    <div class="bank-feedback-item__top">
      <div class="bank-feedback-item__top--phases">
        <span>{{ feedback.phases ? feedback.phases.join(', ') : '' }}</span>
        <div class="header-bar">
          {{ $t('Start Date') }}
          <div v-html="dateFormatInTimeZone(feedback.session_start)" class="date-time-zone"></div>
        </div>
        <div class="header-bar">
          {{ $t('End Date') }}
          <div v-html="dateFormatInTimeZone(feedback.session_end)" class="date-time-zone"></div>
        </div>
      </div>

      <div class="bank-feedback-item__top--status">
        <div
          v-if="feedback.status.statusName === SurveyStatuses.LIVE"
          class="d-flex align-items-center justify-content-end"
        >
          <img width="18" src="/media/buying/icons/liveIcon.svg" alt="">
          <p class="fw-bolder m-2">{{ $t(SurveyStatuses.LIVE) }}</p>
        </div>
        <StatusComponent
          v-else
          :text="feedback.status.statusName"
          :type="feedback.status.statusClass"
        />
      </div>
    </div>

    <div class="bank-feedback-item__content">
      <div class="bank-feedback-item__content--session">
        <router-link :to="`/bank/feedback/feedback-session/${feedback.id}/result`" class="bank-feedback-item__content--title">
          {{ $t('Session ID') }}: {{ feedback.id }}
        </router-link>
        <div class="session-stats">
          <div class="session-stats__item">
            <div class="session-stats__item--title">{{ $t('Score') }}</div>
            <div class="session-stats__item--value" :class="getStatusClass(feedback.stats.score)">
              {{ numberDecimalsFormat(feedback.stats.score) }}
            </div>
          </div>
          <div class="session-stats__item">
            <div class="session-stats__item--title">{{ $t('Importance') }}</div>
            <div class="session-stats__item--value">
              {{ numberDecimalsFormat(feedback.stats.importance) }}
            </div>
          </div>
        </div>
      </div>

      <div class="bank-feedback-item__content--creator">
        <div class="session-creator">
          <div class="session-creator__title">{{ $t('Created by') }}</div>
          <div class="session-creator__name" v-if="feedback.is_bank_survey">
            <img :src="feedback.creator.icon_path" :alt="feedback.creator.full_name">
            <span>{{ feedback.creator.full_name }}</span>

            <div class="session-creator__bank-label">
              {{ $t('Bank user') }}
            </div>
          </div>
          <div class="session-creator__name" v-else>
            <img :src="feedback.creator.icon_path" :alt="feedback.creator.name">
            <span>{{ feedback.creator.name }}</span>
          </div>
        </div>
        <div class="session-stats">
          <div class="session-stats__item">
            <div class="session-stats__item--title">{{ $t('Respondents') }}</div>
            <div class="session-stats__item--value">
              {{ feedback.stats.respondents }}
            </div>
          </div>
          <div class="session-stats__item">
            <div class="session-stats__item--title">{{ $t('Reply Rate') }}</div>
            <div class="session-stats__item--value">
              {{ feedback.stats.replied_rate }}%
            </div>
          </div>
        </div>
      </div>

      <div class="bank-feedback-item__content--info">
        <div class="session-info">
          <div class="session-info__title">
            {{ $t('Product Areas Included') }}
          </div>
          <div class="session-info__value">
            <div class="session-info__value--item" v-for="area in feedback.product_areas.slice(0, 3)">{{ area }}</div>
            <el-dropdown v-if="feedback.product_areas.length > 3">
            <span class="session-info__value--item cursor-pointer">
              +{{ feedback.product_areas.length - 3 }}
            </span>
              <template #dropdown>
                <template v-for="(area, index) in feedback.product_areas">
                  <div v-if="index > 2" class="footer-product-area__dropdown">{{ area }}</div>
                </template>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>

      <div class="bank-feedback-item__content--info country-info">
        <div class="session-info">
          <div class="session-info__title">
            {{ $t('Countries Included') }}
          </div>
          <div class="session-info__value">
            <div class="session-info__value--item" v-for="area in feedback.countries.slice(0, 3)">{{ area }}</div>
            <el-dropdown v-if="feedback.countries.length > 3">
            <span class="session-info__value--item cursor-pointer">
              +{{ feedback.countries.length - 3 }}
            </span>
              <template #dropdown>
                <template v-for="(area, index) in feedback.countries">
                  <div v-if="index > 2" class="footer-product-area__dropdown">{{ area }}</div>
                </template>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="bank-feedback-item__bottom">
      <router-link :to="`/bank/feedback/feedback-session/${feedback.id}/result`" class="bank-feedback-item__bottom--btn">
        <span>{{ feedback.status.statusName === SurveyStatuses.LIVE ? $t('View Session') : $t('See Results') }}</span>
        <img src="/media/buying/icons/arrow-left-black.svg" alt="">
      </router-link>
    </div>
  </div>
</template>
<script>

import { SurveyStatuses } from "@/store/models/SurveyModel";
import StatusComponent from "@/buying-teams/shared-components/utils/StatusComponent";
import { dateFormatInTimeZone, numberDecimalsFormat, getStatusClass } from "@/core/helpers/GlobalHelper";
import { Constants } from "@/core/config/constants";

export default {
  name: "BankFeedbackItem",
  components: {StatusComponent},
  props: {
    feedback: Object,
    showFilter: Boolean,
  },
  data(){
    return {
      dateFormatInTimeZone,
      SurveyStatuses,
      numberDecimalsFormat,
      getStatusClass,
      Constants
    }
  },
};
</script>

<style lang="scss">
@mixin list-border {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  padding: 12px;
  border-bottom: 1px solid #F2F2F2;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.bank-feedback-item {
  border-right: 6px solid transparent;
  box-shadow: 0 6px 29px rgb(0 0 0 / 9%);
  border-radius: 12px;
  margin-bottom: 8px;
  overflow: hidden;
  &__live {
    border-color: #435BF4;
  }
  &__success {
    border-color: #28A57F;
  }
  &__secondary {
    border-color: #B9B9B9;
  }
  &__danger {
    border-color: #EA3333;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    background: #fafafa;
    &--time {
      font-weight: 400;
      font-size: 14px;
      line-height: 158.02%;
      color: #969696;
      margin-bottom: 0;
    }
    &--phases {
      display: flex;
      align-items: center;
      width: 100%;
      overflow-x: auto;
      padding-bottom: 5px;
      margin-bottom: -5px;
      margin-right: 10px;
      > * {
        white-space: nowrap;
      }
      > span {
        font-weight: 400;
        font-size: 11px;
        line-height: 158.02%;

        &:not(:last-child) {
          border-right: 1px solid #969696;
          padding-right: 11px;
        }

        &:not(:first-child) {
          padding-left: 11px;
        }
      }
      .header-bar {
        padding: 0 10px;
        font-weight: 400;
        font-size: 11px;
        line-height: 158.02%;
        color: #969696;

        span {
          color: #000000;
        }

        &:not(:last-child) {
          border-right: 1px solid #969696;
        }
        &:nth-child(1) {
          color: #030303;
        }
      }
    }
    &--status {
      white-space: nowrap;
    }
  }
  &__content {
    &--session {
      @include list-border;
      a {
        margin-bottom: 0;
        &:hover {
          color: #435BF4;
        }
      }
    }
    &--creator {
      @include list-border;
    }
    &--info {
      @include list-border;
      &.country-info {
        border-bottom: none;

        @media (min-width: 769px) {
          .session-info {
            width: calc(100% - 144px);
          }
        }
      }
    }
    &--title {
      font-weight: 700;
      font-size: 16px;
      line-height: 158.02%;
      color: #2B2B2B;
      margin-bottom: 4px;
    }
  }

  .session-creator {
    display: flex;
    align-items: center;
    &__title {
      font-weight: 400;
      font-size: 11px;
      line-height: 158.02%;
      color: #969696;
      margin-right: 22px;
    }
    &__name {
      display: flex;
      align-items: center;
      img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-right: 8px;
      }
      span {
        font-weight: 700;
        font-size: 12px;
        line-height: 158.02%;
        color: #2B2B2B;
      }
    }
    &__bank-label {
      background: #F8F8F8;
      border-radius: 5px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #969696;
      padding: 4px 16px;
      margin-left: 8px;
    }
  }

  .session-stats {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 10px;
    &__item {
      display: flex;
      align-items: center;
      &--title {
        font-weight: 400;
        font-size: 12px;
        line-height: 158.02%;
        color: #969696;
        margin-right: 5px;
      }
      &--value {
        padding: 4px 12px;
        border-radius: 4px;
        font-weight: 700;
        font-size: 16px;
        line-height: 158.02%;
        &.status-danger {
          font-weight: 800;
          background: #FFEDED;
          color: #EA3333;
        }
        &.status-warning {
          font-weight: 800;
          background: #FEF9E7;
          color: #E3A84A;
        }
        &.status-success {
          font-weight: 800;
          background: #EEF7F4;
          color: #28A57F;
        }
      }
    }
  }

  .session-info {
    display: flex;
    align-items: center;
    overflow-x: auto;
    &__title {
      font-weight: 400;
      font-size: 12px;
      line-height: 158.02%;
      color: #969696;
      margin-right: 17px;
      min-width: 133px;
    }
    &__value {
      display: flex;
      gap: 6px;
      > * {
        white-space: nowrap;
      }
      &--item {
        background: #F6F6F6;
        border-radius: 5px;
        padding: 8px 7px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
      }
      .el-dropdown > div {
        display: flex;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
    margin: 0 20px;
    margin-top: -46px;
    padding-bottom: 16px;
    a {
      background: rgba(67, 91, 244, 0.05);
      border-radius: 300px;
      padding: 12px 24px;
      font-weight: 500;
      font-size: 14px;
      line-height: 13px;
      color: #435BF4;
      display: flex;
      align-items: center;
      img {
        margin-left: 6px;
        filter: invert(26%) sepia(50%) saturate(4277%) hue-rotate(229deg) brightness(100%) contrast(92%);
      }
      &:hover {
        background: #435bf424;
      }
    }
  }

  &.show-filter {
    .bank-feedback-item__bottom {
      margin-top: 0;
      justify-content: flex-start;
    }
  }

  @media (min-width: 769px) {
    &__content {
      &--session,
      &--creator {
        & > * {
          white-space: nowrap;
        }
      }
    }
  }

  @media (max-width: 768px) {
    &__content {
      &--creator,
      &--session {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .session-stats {
      margin-left: 0;
      margin-top: 10px;
    }
    &__bottom {
      margin-top: 0;
      justify-content: flex-start;
    }
  }

  @media (max-width: 600px) {
    &__top {
      flex-direction: column-reverse;
      align-items: flex-start;
      &--phases {
        margin-right: 0;
      }
      > div:not(.bank-feedback-item__top--phases) {
        margin-top: 0;
        margin-bottom: 10px;
      }
    }
    &__content {
      &--session,
      &--creator,
      &--info {
        margin: 0 0 0 5px;
      }
    }
    .session-stats__item--value {
      padding: 2px 8px;
      font-size: 12px;
    }
    .session-creator {
      flex-direction: column;
      align-items: flex-start;
      &__title {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }
}
</style>
<style lang="scss">
.footer-product-area__dropdown {
  padding: 5px 10px;
}
</style>
